import React, {useEffect} from "react";
import {useAdminAuth} from "shared/hooks/adminAuthContext/useAdminAuth";
import {WS_SERVERS} from "shared/constants/wsServers";
import {BcService} from "shared/services/BroadcastChannelService";
import {store} from "shared/store/store";
import {_getToken, _handleErrorCode} from "./utils";
import {useAppSelector} from "shared/hooks/useAppSelector";
import {connected, disconnected, initWS} from "shared/ReduxSlice/webSocketSlice/WSSlice";
import {IWSState} from "shared/ReduxSlice/webSocketSlice/types";
import {
  chatsInit,
  clearChatsState,
  setChatsIsInit,
} from "shared/ReduxSlice/chatSlice/chatSlice";

import {ChatEvents} from "shared/utils/chatUtils/ChatEvents";
import {resetMiddleware} from "shared/store/middlewares/resetMiddleware";
import {resetState} from "shared/store/resetState";
let rid = 1;
let reconnectAttempts = 0;
export const ridCounter = () => rid++;

const webSockets = {} as IWSState;

export const WsWrapper = ({children}: {children: React.ReactNode}) => {
  const wsSlice = useAppSelector((store) => store.WSSlice);
  const {user} = useAdminAuth();
  const {isMainApp, tabsCount} = store.getState().broadcastChannelSlice;

  const webSocketConnectV2 = React.useCallback(
    (
      server: {name: string; url: string} = WS_SERVERS.v2,
      maxReconnectAttempts: number,
    ): Promise<unknown> =>
      new Promise((res, rej) => {
        const dispatch = store.dispatch;
        let connectedID = "";

        // если можно подключаться, подключается главная вкладка
        if (isMainApp) {
          // подключаемся к сокетам
          _getToken()
            .then((res) => {
              if ("error" in res) {
                console.error(res.error);
                return;
              }
              connectedID = res?.token;
              webSockets[server.name] = {
                ...webSockets[server.name],
                ws: new WebSocket(server.url + connectedID),
              };
              return webSockets[server.name].ws;
            })
            .then((ws) => {
              if (!ws) {
                return;
              }

              ws.onmessage = async (data) => {
                const json = JSON.parse((await data.data.text()) || "");
                console.log("Ответ ws: ", json);
                if (json.event === "ConnectionAccepted") {
                  webSockets[server.name] = {
                    ...webSockets[server.name],
                    isConnected: true,
                    userId: json.message.userId,
                  };

                  dispatch(initWS({...json, server: server.name}));
                }

                BcService.handleBcSendGlobalMessage({
                  response: json,
                  meta: {
                    userId: wsSlice[server.name]?.userId,
                  },
                });
              };

              const handleBsListener = (messageEvent: MessageEvent) => {
                if (messageEvent && messageEvent.data.request) {
                  const {
                    message = {},
                    event = "",
                    rid = ridCounter(),
                  } = messageEvent.data.request;
                  try {
                    if (ws.readyState === 1 && event) {
                      ws.send(JSON.stringify({message, event, rid}));
                    }
                  } catch (e) {
                    console.warn("не удалось отправить данные, ошибка: ", e);
                  }
                }
              };

              ws.onopen = () => {
                webSockets[server.name] = {...webSockets[server.name], connectedID};
                dispatch(connected({server: server.name, connectedID}));
                BcService.messageReceiver.addEventListener("message", handleBsListener);
                // console.log("i connected " + connectedID);
                res({idConnected: connectedID, ws});
                reconnectAttempts = 0;
              };

              ws.onclose = (e) => {
                // TODO добавить сюда обработчик выхода из аккаунта при получении User already connected
                dispatch(disconnected({server: server.name}));
                dispatch(clearChatsState());
                webSockets[server.name].ws = null;
                console.log("Соединение WebSocket разорвано. Причина: " + e.reason);
                console.log(e);
                BcService.messageReceiver.removeEventListener(
                  "message",
                  handleBsListener,
                );
                // if (reconnectAttempts < maxReconnectAttempts) {
                //   reconnectAttempts++;
                //   setTimeout(function () {
                //     webSocketConnectV2(server, maxReconnectAttempts);
                //   }, 10000 * (reconnectAttempts + 1));
                // }

                return rej(e);
              };
            })
            .catch((err) => console.error(err));
        }
      }),
    [isMainApp],
  );

  const handleBeforeUnload = () => {
    webSocketDisconnect();
  };

  useEffect(() => {
    if (!user) return;
    if (wsSlice[WS_SERVERS.v2.name]?.isConnected) return;
    if (!isMainApp) return;

    webSocketConnectV2(undefined, 3);

    window.addEventListener("beforeunload", handleBeforeUnload);
  }, [user, isMainApp, tabsCount]);

  return <>{children}</>;
};

//функция отключения сокетов, принимает причину закрытия и сервер, например WS_SERVERS.v2
// если не передавать сервер, то разрываются все соединения
export function webSocketDisconnect(reason = "logout", server = {name: ""}) {
  if (server && webSockets[server.name]) {
    webSockets[server.name]?.ws?.close();
  } else {
    Object.values(webSockets).forEach((x) => x.ws?.close(1000, reason));
  }
}
