import React from "react";
import {HasNoAvailableRoles} from "pages/hasNoAvailableRoles/HasNoAvailableRoles";
import {useAdminAuth} from "shared/hooks/adminAuthContext/useAdminAuth";
import {FATHER_ID} from "shared/constants/father";

/* check permissions:  */

export const PermissionByRolesWrapper = ({children}: {children: React.ReactNode}) => {
  const {user} = useAdminAuth();
  const roles = React.useMemo(() => {
    return user?.roles?.map((role) => role?.name) || [];
  }, [user]);

  const isFather = user?.id === FATHER_ID;

  if (!isFather && (!roles || !roles?.length)) {
    return <HasNoAvailableRoles />;
  }

  return <>{children}</>;
};
