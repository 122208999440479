import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import {format} from "date-fns";
import {
  ArrayField,
  Datagrid,
  FunctionField,
  Labeled,
  List,
  ListActions,
  minLength,
  NullableBooleanInput,
  NumberField,
  SearchInput,
  SimpleList,
  SimpleShowLayout,
  TextField,
  useRecordContext,
} from "react-admin";
import {MyBooleanField} from "shared/components/MyBooleanField/MyBooleanField";
import {PATTERN_DATE_SHORT, PATTERN_DATE_TIME_FULL} from "shared/constants/dates";
import {getDataGridStyles} from "shared/styles/dataGridStyles";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import {DownLoadInVoiceButton} from "./components/DownLoadInVoiceButton";
import {MarkAsPaidButton} from "./components/MarkAsPaidButton";
import React from "react";
import {ROUTES} from "shared/constants/ROUTES";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import {UI_Modal} from "shared/components/UI_Modal/UI_Modal";
import {palette} from "app/providers/themeProvider/lib/paleteOverrides/paleteOverrides";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {useDisclosure} from "shared/hooks/useDisclosure";

const filters = [
  <SearchInput
    source="search"
    alwaysOn
    validate={minLength(3)}
    InputProps={{
      endAdornment: (
        <InputAdornment position={"end"}>
          <Tooltip
            title={
              <>
                Поиск осуществляется при длине больше 3ех символов.
                <br />
                Поиск по названию компании, инн компании, hid промо, фио пользователя.
              </>
            }
          >
            <LiveHelpIcon sx={{cursor: "help"}} />
          </Tooltip>
        </InputAdornment>
      ),
    }}
  />,

  <NullableBooleanInput
    source="isPaid"
    label="Фильтр по оплате"
    alwaysOn
    sx={{minWidth: "200px"}}
    nullLabel="Показать все записи"
    trueLabel="Оплаченные"
    falseLabel="Не оплаченные"
  />,
];

export const InvoiceList = ({
  companyId,
  isCompanyList,
}: {
  companyId?: string;
  isCompanyList?: boolean;
}) => {
  const params = React.useMemo(() => {
    const filters = {companyId};
    const filteredFilters = Object.fromEntries(
      Object.entries(filters).filter(([key, value]) => !!value),
    );
    let resource = ROUTES.INVOICE.path;

    if (Object.keys(filteredFilters).length) {
      const queryParams = Object.entries(filteredFilters)
        .map(([key, value]) => `${key}=${value}`)
        .join("&");
      resource += `?${queryParams}`;
    }
    return {filters: filteredFilters, resource};
  }, [companyId]);

  return (
    <List
      actions={<ListActions exporter={false} />}
      filters={filters}
      sort={{field: "createdAt", order: "DESC"}}
      resource={params.resource}
      filterDefaultValues={params.filters}
    >
      <Fields isCompanyList={isCompanyList} />
    </List>
  );
};

function Fields({isCompanyList}: {isCompanyList?: boolean}) {
  return (
    <Datagrid
      bulkActionButtons={false}
      expand={<RowDetail />}
      expandSingle
      sx={({palette}) => ({
        ...getDataGridStyles(palette),
      })}
    >
      <TextField source={"hid"} label={"HId счета"} />
      <FunctionField
        label="Создан"
        source="createdAt"
        render={(record) =>
          record?.createdAt ? format(record.createdAt, PATTERN_DATE_TIME_FULL) : null
        }
      />

      <FunctionField
        label="№ счета"
        render={(record) =>
          `${record?.numPrefix ? record.numPrefix : ""}${record?.num ? record.num : ""}${
            record?.numSuffix ? record.numSuffix : ""
          }`
        }
        sortable={false}
      />

      <MyBooleanField label="Оплачен" source="isPaid" sortable={false} />

      <FunctionField
        label="Дата оплаты"
        source="paidDate"
        render={(record) =>
          record?.paidDate ? format(record.paidDate, PATTERN_DATE_SHORT) : null
        }
        sortable={false}
      />

      {!isCompanyList && (
        <TextField label="Плательщик" source="payerName" sortable={false} />
      )}

      <NumberField label="Сумма" source="totalAmount" textAlign="left" sortable={false} />
    </Datagrid>
  );
}

function RowDetail() {
  const record = useRecordContext();

  return (
    <SimpleShowLayout
      sx={{
        pl: 8,
        py: 4,
        position: "relative",
      }}
    >
      <TextField source={"id"} label={"Id счета"} />

      <FunctionField
        label="Создан"
        source="createdAt"
        render={(record) =>
          record?.createdAt ? format(record.createdAt, PATTERN_DATE_TIME_FULL) : null
        }
      />

      <FunctionField
        label="Номер счета"
        render={(record) =>
          `${record?.numPrefix ? record.numPrefix : ""}${record?.num ? record.num : ""}${
            record?.numSuffix ? record.numSuffix : ""
          }`
        }
      />

      <TextField source={"paymentPurpose"} label={"Назначение счета"} />

      <Box sx={{display: "flex", gap: 10}}>
        <Labeled label="Оплачен">
          <MyBooleanField source="isPaid" sx={{justifyContent: "flex-start"}} />
        </Labeled>
        <Labeled label="Когда оплачен">
          <FunctionField
            source="paidDate"
            render={(record) =>
              record?.paidDate ? format(record.paidDate, PATTERN_DATE_SHORT) : null
            }
            sx={{height: "20px", alignContent: "center"}}
          />
        </Labeled>
      </Box>

      <Divider />
      <Box sx={{display: "flex", width: "100%"}}>
        <Stack sx={{flex: 1, maxWidth: "400px", width: "100%", gap: 2}}>
          <Labeled label="Имя плательщика">
            <TextField source="payerName" />
          </Labeled>
          <Labeled label="ИНН плательщика">
            <TextField source="payerInn" />
          </Labeled>
          <Labeled label="КПП плательщика">
            <TextField source="payerKpp" />
          </Labeled>
          <Labeled label="Адрес плательщика">
            <TextField source="payerAddress" />
          </Labeled>
          {record?.payerBankName && (
            <Labeled label="Банк плательщика">
              <TextField source="payerBankName" />
            </Labeled>
          )}
          {record?.payerBic && (
            <Labeled label="БИК плательщика">
              <TextField source="payerBic" />
            </Labeled>
          )}
          {record?.payerKs && (
            <Labeled label="Корреспондентский счет плательщика">
              <TextField source="payerKs" />
            </Labeled>
          )}
          {record?.payerRs && (
            <Labeled label="Расчетный счет плательщика">
              <TextField source="payerRs" />
            </Labeled>
          )}
        </Stack>
        <Stack sx={{flex: 1, maxWidth: "400px", width: "100%", gap: 2}}>
          <Labeled label="Имя получателя">
            <TextField source="recipientName" />
          </Labeled>
          <Labeled label="ИНН получателя">
            <TextField source="recipientInn" />
          </Labeled>
          <Labeled label="КПП получателя">
            <TextField source="recipientKpp" />
          </Labeled>
          <Labeled label="Адрес получателя">
            <TextField source="recipientAddress" />
          </Labeled>
        </Stack>
      </Box>
      <Divider />
      <TextField source="amountNoNds" label="Сумма без НДС" />
      <TextField source="ndsAmount" label="Сумма НДС" />
      <TextField source="ndsPercent" label="Процент НДС" />
      <TextField source="totalAmount" label="Сумма" />
      <Divider />

      <ArrayField source="purchases" label="Покупки:">
        <SimpleList
          linkType={false}
          primaryText={
            <Labeled label="Название">
              <TextField source="alias" />
            </Labeled>
          }
          secondaryText={
            <Labeled label="Сумма">
              <TextField source="totalAmount" />
            </Labeled>
          }
        />
      </ArrayField>

      <MarkAsPaidButton />
      <DownLoadInVoiceButton />

      <ShowInvoiceDocsButton />
    </SimpleShowLayout>
  );
}

const docTypeMapping = {
  INVOICE: "Счет на оплату",
  FACTURA_ADVANCE: "Счет фактура на аванс",
  FACTURA: "Счет фактура",
  ACT: "Акт",
};

function ShowInvoiceDocsButton() {
  const record = useRecordContext();
  const finDocs = record?.finDocs || [];
  const {isOpen, onClose, onOpen} = useDisclosure();

  return (
    <>
      <Tooltip title="Посмотреть документы">
        <span>
          <IconButton
            sx={{position: "absolute", top: 20, right: 80}}
            onClick={onOpen}
            disabled={!finDocs?.length}
            color="primary"
          >
            <PictureAsPdfOutlinedIcon fontSize="large" />
          </IconButton>
        </span>
      </Tooltip>

      <UI_Modal
        modalProps={{
          open: isOpen,
          onClose: onClose,
        }}
        titleProps={{title: "Файлы"}}
      >
        <Table>
          <colgroup>
            {["140px", "80px", "unset", "60px"].map((width, i) => (
              <col key={i} width={width} />
            ))}
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell>Тип</TableCell>
              <TableCell>Дата</TableCell>
              <TableCell>Сумма</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {finDocs?.map((file) => {
              const createdDate = format(new Date(file?.createdAt), PATTERN_DATE_SHORT);
              return (
                <TableRow key={file?.id}>
                  <TableCell>{docTypeMapping[`${file?.docType}`]}</TableCell>
                  <TableCell>{createdDate}</TableCell>
                  <TableCell>{file.totalAmount}</TableCell>
                  <TableCell align="center">
                    <Typography
                      variant="16px"
                      component={Link}
                      href={file?.docFile?.url}
                      download
                      sx={{color: palette.primary.main}}
                    >
                      <FileDownloadOutlinedIcon />
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </UI_Modal>
    </>
  );
}
