import {Components, Theme} from "@mui/material";

export const muiTableBody: Components<Theme>["MuiTableBody"] = {
  styleOverrides: {
    root: ({theme}) => ({
      "& .MuiTableCell-root": {
        fontSize: "13px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "110%",
        borderRight: `1px solid ${theme.palette.tableBodyBorder.main}`,
        borderBottom: `1px solid ${theme.palette.tableBodyBorder.main}`,
        ":first-of-type": {
          borderLeft: `1px solid ${theme.palette.tableBodyBorder.main}`,
        },
      },
      "& .MuiTableRow-root": {
        height: "42px",
        ":first-of-type": {
          borderTop: `1px solid ${theme.palette.tableBodyBorder.main}`,
        },
      },
    }),
  },
};
