import {Stack} from "@mui/material";
import {ChatHeader} from "./header/ChatHeader";
import {ChatFooter} from "./footer/ChatFooter";
import {ChatBody} from "./body/ChatBody";

export const ChatComponent = () => {
  return (
    <Stack sx={{height: "90vh"}}>
      <ChatHeader />
      <ChatBody />
      <ChatFooter />
    </Stack>
  );
};
