import axios from "axios";
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";

import {AdminAuthProvider} from "./providers/adminProvider/adminProvider";
import {ToastProvider} from "./providers/toastProvider/toastProvider";
import {WithThemeProvider} from "./providers/themeProvider";
import {AuthWrapper} from "./wrappers/AuthWrapper/AuthWrapper";

import {AdminWrapper} from "./wrappers/AdminWrapper/AdminWrapper";
import {PermissionByRolesWrapper} from "./wrappers/PermissionWrapper/PermissionWrapper";
import {FatherWrapper} from "./wrappers/FatherWrapper/FatherWrapper";
import {WsWrapper} from "./wrappers/WsWrapper/WsWrapper";
import {store} from "shared/store/store";
import {useBcHelpers} from "shared/hooks/useBcHelpers";
import {AdminPanel} from "./ui/AdminPanel";
import {useMainTabListenerTest} from "shared/hooks/useMainTabListenerTest";

axios.defaults.withCredentials = true;

const App = () => {
  useMainTabListenerTest();
  useBcHelpers();
  return (
    <AuthWrapper>
      <AdminWrapper>
        <WsWrapper>
          <FatherWrapper>
            {(isFather) => (
              <PermissionByRolesWrapper>
                <AdminPanel isFather={isFather} />;
              </PermissionByRolesWrapper>
            )}
          </FatherWrapper>
        </WsWrapper>
      </AdminWrapper>
    </AuthWrapper>
  );
};

const AppWithContext = () => {
  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <ToastProvider>
            <AdminAuthProvider>
              <App />
            </AdminAuthProvider>
          </ToastProvider>
        </BrowserRouter>
      </Provider>
    </>
  );
};

export default WithThemeProvider(AppWithContext);
