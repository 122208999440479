import axios from "axios";
import {DataProvider} from "react-admin";
import {API_URL} from "shared/constants/apiURL";
import {handleConvertSalesCompanyReqBody} from "../utils/helpers";
import {defaultProvider} from "./defaultProvider";
import {ROUTES} from "shared/constants/ROUTES";

export const salesCabProvider = {
  ...defaultProvider(),

  getList: async (resource = "", params) => {
    const {data} = await axios.post(
      `${API_URL}/${ROUTES.COMPANY_LIST.path}`,
      handleConvertSalesCompanyReqBody(params),
    );
    const total = data?.totalCount || 0;

    const replacedData = (data?.items || []).map((item) => {
      item.isActive = !item.isActive;
      return item;
    });

    return {total, data: replacedData};
  },
} as DataProvider;
