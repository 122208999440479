import React from "react";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import {setIsMainTabApp, setTabsCount} from "shared/ReduxSlice/bcSlice/bcSlice";
import {BcService} from "shared/services/BroadcastChannelService";

const currTab = new Date().getTime();
let sharedWorkerTabs: number[] = [currTab];

const useSwHelpers = () => {
  const [isMainTab, setIsMainTab] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const worker = new SharedWorker(
      new URL("../useWebWorker/counter.ts", import.meta.url),
    );
    worker.port.postMessage({type: "connect"});

    worker.port.onmessage = ({data}) => {
      const {tabsCount, tabNumber} = data;

      dispatch(setTabsCount(tabsCount));

      const isMain = tabNumber === 1;
      setIsMainTab(isMain);
      dispatch(setIsMainTabApp(isMain));
    };

    const unloadHandler = () => {
      worker.port.postMessage({type: "disconnect"});
      worker.port.close();
    };

    window.addEventListener("beforeunload", unloadHandler);

    return () => {
      unloadHandler();
      window.removeEventListener("beforeunload", unloadHandler);
    };
  }, [dispatch]);

  return {isMainTab};
};

const BCWorkerListener = () => {
  const [isMainTab, setIsMainTab] = useState(false);
  const dispatch = useDispatch();

  const messageHandler = React.useCallback(
    ({data}: MessageEvent) => {
      if (data.connectTab || data.disconnectTab) {
        if (data.connectTab && !sharedWorkerTabs.includes(data.connectTab)) {
          sharedWorkerTabs = [...sharedWorkerTabs, data.connectTab].sort();
        } else if (data.disconnectTab) {
          sharedWorkerTabs = sharedWorkerTabs.filter((tab) => tab !== data.disconnectTab);
        }

        dispatch(setTabsCount(sharedWorkerTabs.length));
        const isMain = sharedWorkerTabs[0] === currTab;
        setIsMainTab(isMain);
        dispatch(setIsMainTabApp(isMain));
      }
    },
    [dispatch],
  );

  const unloadHandler = React.useCallback(() => {
    BcService.handleBcSendGlobalMessage({disconnectTab: currTab});
    BcService.messageReceiver?.removeEventListener("message", messageHandler);
  }, [messageHandler]);

  const connectHandler = React.useCallback(() => {
    BcService.handleBcSendGlobalMessage({connectTab: currTab});
    BcService.messageReceiver?.addEventListener("message", messageHandler);
    window.addEventListener("beforeunload", unloadHandler);
  }, [messageHandler, unloadHandler]);

  const disconnectHandler = React.useCallback(() => {
    window.removeEventListener("beforeunload", unloadHandler);
  }, [unloadHandler]);

  useEffect(() => {
    console.log("here?");
    connectHandler();

    return () => {
      disconnectHandler();
    };
  }, [connectHandler, disconnectHandler]);

  return {isMainTab};
};

/**
 * слушатель на авторизацию пользователя и закрытие главной вкладки
 * для подключения websocket, начала чата и уведомлений
 */
export const useMainTabListenerTest = (() => {
  if ("SharedWorker" in window) {
    return useSwHelpers;
  } else if ("BroadcastChannel" in window) {
    return BCWorkerListener;
  }
  // тестирование без подключения ws на старых телефонах (закончить тестирование 02.11)
  else {
    const isMainTabInterval = () =>
      setInterval(() => console.log({heartbeat: "true"}), 10000);
    return () => ({
      isMainApp: false,
      tabsCount: 0,
      connectHandler: () => undefined,
      disconnectHandler: () => undefined,
      isMainTabInterval,
    });
  }
})();
