import React from "react";
import {Route, useNavigate} from "react-router-dom";
import {
  Admin,
  AppBar,
  CustomRoutes,
  Layout,
  LoadingIndicator,
  Resource,
  ToggleThemeButton,
} from "react-admin";
import {Box, Button} from "@mui/material";
import TicketList from "../../pages/tickets/ListTickets/ListTickets";
import TicketsEditCompany from "../../pages/tickets/TicketsEditCompany/TicketsEditCompany";
import TicketsEditProcedures from "../../pages/tickets/TicketsEditProcedures/TicketsEditProcedures";
import {TicketsEditComplaint} from "../../pages/tickets/TicketsEditComplaint/TicketsEditComplaint";
import Profile from "../../pages/profile/Profile";
import SafeDealShow from "../../pages/safeDeal/SafeDealShow";
import {FAQList, FAQCreate, FAQEdit} from "../../pages/FAQ";
import {BlogCreate, BlogEdit, BlogList} from "../../pages/blog";
import {CompaniesList, CompanyShow} from "../../pages/companies";
import {NewsCreate, NewsEdit, NewsList} from "../../pages/news";
import Control from "../../pages/control/control";
import {NotFound} from "../../pages/notFound/NotFound";
import {UserList} from "../../pages/users/UserList";
import {OperatorList} from "../../pages/father/OperatorList";
import {SafeDealList} from "../../pages/safeDeal/SafeDealList";
import {OperatorCreate} from "../../pages/father/OperatorCreate";
import {OperatorEdit} from "../../pages/father/OperatorEdit";
import {AllBlocksList} from "../../pages/allBlocks/AllBlocksList";
import {ChatComponent} from "../../pages/chat/ChatList";
import {
  GroupTariffsList,
  GroupTariffsCreate,
  GroupTariffsEdit,
} from "../../pages/groupTariffs";
import {TariffsEdit, TariffsList, TariffsCreate} from "../../pages/tariffs";
import {GroupTariffsShow} from "../../pages/groupTariffs/GroupTariffsShow";
import {PromoCreate, PromoList} from "../../pages/promo";
import {InvoiceList} from "../../pages/invoice";
import {AssetsList} from "../../pages/assets";
import MySideBar, {FatherSideBar} from "../../widgets/MySideBar/MySideBar";
import {Auth} from "shared/services/auth";
import {usePermission} from "shared/hooks/usePermission";
import {
  complaintsTicketsTheme,
  FATHER_ROUTES,
  PERMISSIONS_FOR_ROUTES,
  ROUTES,
} from "shared/constants/ROUTES";
import {useAdminAuth} from "shared/hooks/adminAuthContext/useAdminAuth";
import {combinedDataProvider} from "../providers/dataProvider/customProvider";
import {THEMES} from "../providers/themeProvider";
import {queryClient, i18nProvider} from "shared/constants/reactAdminDefault";
import {SalesCab} from "pages/salesCab";

type AdminPanelProps = {
  isFather: boolean;
};

export const AdminPanel = ({isFather}: AdminPanelProps) => {
  const {user, setUser} = useAdminAuth();
  const navigate = useNavigate();

  const {getRoutePermission} = usePermission();
  const handleRoutesPermission = React.useCallback(
    (name: keyof typeof PERMISSIONS_FOR_ROUTES): boolean =>
      getRoutePermission(name, user),
    [user, getRoutePermission],
  );

  const clearLocalStorage = () => {
    localStorage.clear();
  };

  const clearSearchParams = () => {
    navigate(window.location.pathname, {replace: true});
  };

  return (
    <Admin
      dataProvider={combinedDataProvider}
      disableTelemetry={true}
      i18nProvider={i18nProvider}
      queryClient={queryClient}
      catchAll={NotFound}
      theme={THEMES.light}
      darkTheme={THEMES.dark}
      layout={(props) => {
        return (
          <Layout
            {...props}
            appBarAlwaysOn
            menu={(props) =>
              isFather
                ? FatherSideBar({...props})
                : MySideBar({...props, handleRoutesPermission})
            }
            appBar={(props) => {
              return (
                <AppBar
                  {...props}
                  sx={({palette}) => ({
                    backgroundColor: palette.primary.main,
                    color: "white",
                    ".MuiToolbar-root": {
                      alignItems: "center",
                    },
                  })}
                  toolbar={
                    <>
                      <ToggleThemeButton />
                      <LoadingIndicator
                        onClick={() => {
                          clearLocalStorage();
                          clearSearchParams();
                        }}
                      />
                    </>
                  }
                >
                  <Box sx={{flex: 1}} />
                  <Button
                    onClick={() => navigate("/profile")}
                    sx={{color: "white !important"}}
                  >
                    Профиль
                  </Button>
                  <Button
                    sx={{color: "white !important"}}
                    onClick={async () => {
                      await Auth.logout();
                      setUser(null);
                    }}
                  >
                    Выйти
                  </Button>
                </AppBar>
              );
            }}
          />
        );
      }}
    >
      {isFather ? (
        <Resource
          name={FATHER_ROUTES.LIST_ADMIN.path}
          list={<OperatorList />}
          create={<OperatorCreate />}
          edit={<OperatorEdit />}
        />
      ) : (
        <>
          {handleRoutesPermission(ROUTES.NEWS.path) && (
            <Resource
              name={ROUTES.NEWS.path}
              list={<NewsList />}
              create={<NewsCreate />}
              edit={<NewsEdit />}
            />
          )}

          {handleRoutesPermission(ROUTES.BLOG.path) && (
            <Resource
              name={ROUTES.BLOG.path}
              list={<BlogList />}
              create={<BlogCreate />}
              edit={<BlogEdit />}
            />
          )}

          {handleRoutesPermission(ROUTES.FAQ.path) && (
            <Resource
              name={ROUTES.FAQ.path}
              list={<FAQList />}
              create={<FAQCreate />}
              edit={<FAQEdit />}
            />
          )}

          {handleRoutesPermission(ROUTES.CHAT.path) && (
            <Resource name={ROUTES.CHAT.path} list={<ChatComponent />} />
          )}

          {handleRoutesPermission(ROUTES.COMPANY_TICKETS.path) && (
            <>
              <Resource name={ROUTES.COMPANY_TICKETS.path} list={<TicketList />} />
              <Resource
                name={`${ROUTES.COMPANY_TICKETS.path}/tickets`}
                show={<TicketsEditCompany />}
              />
            </>
          )}

          {handleRoutesPermission(ROUTES.COMPANY_LIST.path) && (
            <Resource
              name={ROUTES.COMPANY_LIST.path}
              list={<CompaniesList />}
              show={<CompanyShow />}
            />
          )}

          {handleRoutesPermission(ROUTES.USERS.path) && (
            <Resource name={ROUTES.USERS.path} list={<UserList />} />
          )}

          {handleRoutesPermission(ROUTES.PROCEDURE_TICKETS.path) && (
            <>
              <Resource name={ROUTES.PROCEDURE_TICKETS.path} list={<TicketList />} />
              <Resource
                name={`${ROUTES.PROCEDURE_TICKETS.path}/tickets`}
                show={<TicketsEditProcedures />}
              />
            </>
          )}

          {handleRoutesPermission(ROUTES.SAFE_DEAL_TICKETS.path) && (
            <>
              <Resource name={ROUTES.SAFE_DEAL_TICKETS.path} list={<SafeDealList />} />
              <Resource
                name={`${ROUTES.SAFE_DEAL_TICKETS.path}/tickets`}
                show={<SafeDealShow />}
              />
            </>
          )}

          {handleRoutesPermission(ROUTES.SOPROCEDURES_TICKETS.path) && (
            <>
              <Resource name={ROUTES.SOPROCEDURES_TICKETS.path} list={<TicketList />} />
              <Resource
                name={`${ROUTES.SOPROCEDURES_TICKETS.path}/tickets`}
                show={<TicketsEditProcedures />}
              />
            </>
          )}

          {handleRoutesPermission(ROUTES.COMPANY_TICKETS_STATUSES.path) && (
            <Resource name={ROUTES.COMPANY_TICKETS_STATUSES.path} list={<TicketList />} />
          )}

          {handleRoutesPermission(ROUTES.USERS_AND_COMPANY_BLOCKS_LIST.path) && (
            <Resource
              name={ROUTES.USERS_AND_COMPANY_BLOCKS_LIST.path}
              list={<AllBlocksList />}
            />
          )}

          {handleRoutesPermission(ROUTES.COMPLAINTS_TICKETS.path) && (
            <>
              <Resource
                name={ROUTES.COMPLAINTS_TICKETS.path}
                list={<TicketList theme={complaintsTicketsTheme} />}
              />

              <Resource
                name={`${ROUTES.COMPLAINTS_TICKETS.path}/tickets`}
                show={<TicketsEditComplaint />}
              />

              <Resource
                name={`${ROUTES.COMPANY_COMPLAINTS_TICKETS.path}/tickets`}
                show={<TicketsEditComplaint />}
              />
            </>
          )}

          {handleRoutesPermission(ROUTES.CONTROL.path) && (
            <CustomRoutes>
              <Route path={ROUTES.CONTROL.path} element={<Control />} />
            </CustomRoutes>
          )}

          {handleRoutesPermission(ROUTES.TARIFFS.path) && (
            <>
              <Resource
                name={ROUTES.GROUP_TARIFFS.path}
                list={<GroupTariffsList />}
                create={<GroupTariffsCreate />}
                edit={<GroupTariffsEdit />}
                show={<GroupTariffsShow />}
              />

              <Resource
                name={ROUTES.TARIFFS.path}
                list={<TariffsList />}
                create={<TariffsCreate />}
                edit={<TariffsEdit />}
              />
            </>
          )}

          {handleRoutesPermission(ROUTES.PROMO.path) && (
            <Resource
              name={ROUTES.PROMO.path}
              list={<PromoList />}
              create={<PromoCreate />}
            />
          )}

          {handleRoutesPermission(ROUTES.INVOICE.path) && (
            <Resource name={ROUTES.INVOICE.path} list={<InvoiceList />} />
          )}

          {handleRoutesPermission(ROUTES.INVOICE.path) && (
            <Resource name={ROUTES.ASSETS.path} list={<AssetsList />} />
          )}

          {handleRoutesPermission(ROUTES.SALES_CABINET.path) && (
            <Resource name={ROUTES.SALES_CABINET.path} list={<SalesCab />} />
          )}
        </>
      )}
      {/* общий */}
      <CustomRoutes>
        <Route path={"/profile"} element={<Profile />} />
      </CustomRoutes>
    </Admin>
  );
};
