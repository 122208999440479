import {Tooltip, IconButton} from "@mui/material";
import {useState} from "react";
import {useRecordContext} from "react-admin";
import {PATTERN_DATE_SHORT} from "shared/constants/dates";
import {useDownloadInvoice} from "../hooks/useDownloadInvoice";
import {format} from "date-fns";
import DownloadIcon from "@mui/icons-material/Download";

export const DownLoadInVoiceButton = () => {
  const {handleDownloadInvoice} = useDownloadInvoice();
  const [isDownloadingInvoice, setIsDownloadingInvoice] = useState(false);

  const record = useRecordContext();
  const fullInvoiceNumber =
    (record?.numPrefix ? record.numPrefix : "") +
    (record?.num ? record.num : "") +
    (record?.numSuffix ? record.numSuffix : "");

  const createdAt = new Date(record?.createdAt);
  const createdDate = format(createdAt, PATTERN_DATE_SHORT);

  return (
    <Tooltip title="Скачать счет">
      <span>
        <IconButton
          sx={{position: "absolute", top: 20, right: 20}}
          onClick={async () => {
            setIsDownloadingInvoice(true);
            await handleDownloadInvoice({
              invoiceId: record?.id as string,
              fileName: `СЧЕТ ${fullInvoiceNumber} от ${createdDate}.pdf`,
            });

            setTimeout(() => setIsDownloadingInvoice(false), 1000);
          }}
          disabled={isDownloadingInvoice}
          color="primary"
        >
          <DownloadIcon fontSize="large" />
        </IconButton>
      </span>
    </Tooltip>
  );
};
